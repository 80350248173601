import { faBrain, faMicrochip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, InputNumber, Select, SelectProps, Space, Switch } from "antd";
import { useParams } from "react-router";
import { useRebalance } from "../api/rebalance";
import { useEffect, useRef, useState } from "react";
import { ClusterRebalanceConfiguration } from "../type/rebalance";
import { configAWSExtraAllocation, configNodeProvisionInstanceArch, configNodeProvisionInstanceFamily,
    configAWSNodeProvisionInstanceTags, configNodeProvisionMaxValues, configNodeProvisionMinValues,
    configAWSNodeProvisionSystemDiskSize, configNodeProvisionZoneID, awsCPUCoresKey, cpuResourceKey,
    extractAWSExtraResourceAllocation, extractGeneralNodeProvisionInstanceArch, extractGeneralNodeProvisionMaxCPUCores,
    extractGeneralNodeProvisionMaxMemoryMiB, extractGeneralNodeProvisionMinCPUCores, extractGeneralNodeProvisionMinMemoryMiB,
    extractAWSGeneralNodeProvisionSystemDiskSizeGiB, extractGeneralNodeProvisionZoneID, extractGPUNodeProvisionMinGPUCards,
    extractGPUNodeProvisionMinGPUMemoryMiB, extractNodeProvisionInstanceFamily, extraNodeProvisionInstanceTags,
    extractNodeProvisionCapacityTypes, configNodeProvisionCapacityTypes, awsMemoryMiBKey, memoryResourceKey, awsMinGPUCardsKey, awsMinGPUMemoryMiBKey } from "../utils/rebalance";
import { loading } from "./Loading";


const archOptions: SelectProps['options'] = [
    { value: 'arm64', label: 'arm64' },
    { value: 'amd64', label: 'amd64' },
];

const capacityOptions: SelectProps['options'] = [
    { value: 'on-demand', label: 'on-demand' },
    { value: 'spot', label: 'spot' },
];

export default function AWSNodeTemplateConfiguration() {
    const { clusterID } = useParams();

    const rebalance = useRebalance();

    const [rebalanceCfg, setRebalanceCfg] = useState<ClusterRebalanceConfiguration>();

    const [generalNodeProvisionInstanceFamily, setGeneralNodeProvisionInstanceFamily] = useState("");
    const latestGeneralNodeProvisionInstanceFamily = useRef(generalNodeProvisionInstanceFamily);

    const [instanceArch, setInstanceArch] = useState<string[]>([]);
    const latestInstanceArch = useRef(instanceArch);

    const [zoneID, setZoneID] = useState("");
    const latestZoneID = useRef(zoneID);

    const [capacityTypes, setCapacityTypes] = useState<string[]>([]);
    const latestCapacityTypes = useRef(capacityTypes);

    const [instanceTags, setInstanceTags] = useState("");
    const latestInstanceTags = useRef(instanceTags);

    const [systemDiskSizeGiB, setSystemDiskSizeGiB] = useState(0);
    const latestSystemDiskSizeGiB = useRef(systemDiskSizeGiB);

    const [extraAllocationCPUMCores, setExtraAllocationCPUCores] = useState(0);
    const latestExtraAllocationCPUMCores = useRef(extraAllocationCPUMCores);

    const [extraAllocationMemoryMiB, setExtraAllocationMemoryMiB] = useState(0);
    const latestExtraAllocationMemoryMiB = useRef(extraAllocationMemoryMiB);

    const [generalNodeProvisionMinCPUCores, setGeneralNodeProvisionMinCPUCores] = useState(0);
    const latestGeneralNodeProvisionMinCPUCores = useRef(generalNodeProvisionMinCPUCores);

    const [generalNodeProvisionMaxCPUCores, setGeneralNodeProvisionMaxCPUCores] = useState(0);
    const latestGeneralNodeProvisionMaxCPUCores = useRef(generalNodeProvisionMaxCPUCores);

    const [generalNodeProvisionMinMemoryMiB, setGeneralNodeProvisionMinMemoryMiB] = useState(0);
    const latestGeneralNodeProvisionMinMemoryMiB = useRef(generalNodeProvisionMinMemoryMiB);

    const [generalNodeProvisionMaxMemoryMiB, setGeneralNodeProvisionMaxMemoryMiB] = useState(0);
    const latestGeneralNodeProvisionMaxMemoryMiB = useRef(generalNodeProvisionMaxMemoryMiB);

    const [gpuNodeProvisionMinGPUCards, setGPUNodeProvisionMinGPUCards] = useState(0);
    const latestGPINodeProvisionMinGPUCards = useRef(gpuNodeProvisionMinGPUCards);

    const [gpuNodeProvisionMinGPUMemoryMiB, setGPUNodeProvisionMinGPUMemoryMiB] = useState(0);
    const latestGPUNodeProvisionMinGPUMemoryMiB = useRef(gpuNodeProvisionMinGPUMemoryMiB);

    const [gpuNodeProvisionEnabled, setGPUNodeProvisionEnabled] = useState(true);
    const latestGPUNodeProvisionEnabled = useRef(gpuNodeProvisionEnabled);

    const [gpuNodeProvisionInstanceFamily, setGPUNodeProvisionInstanceFamily] = useState("");
    const latestGPUNodeProvisionInstanceFamily = useRef(gpuNodeProvisionInstanceFamily);

    const [buttonLoading, setButtonLoading] = useState(false)

    useEffect(() => {
        latestGeneralNodeProvisionInstanceFamily.current = generalNodeProvisionInstanceFamily;
        latestGeneralNodeProvisionMinCPUCores.current = generalNodeProvisionMinCPUCores;
        latestGeneralNodeProvisionMaxCPUCores.current = generalNodeProvisionMaxCPUCores;
        latestGeneralNodeProvisionMinMemoryMiB.current = generalNodeProvisionMinMemoryMiB;
        latestGeneralNodeProvisionMaxMemoryMiB.current = generalNodeProvisionMaxMemoryMiB;
        latestGPUNodeProvisionEnabled.current = gpuNodeProvisionEnabled;
        latestGPINodeProvisionMinGPUCards.current = gpuNodeProvisionMinGPUCards;
        latestGPUNodeProvisionMinGPUMemoryMiB.current = gpuNodeProvisionMinGPUMemoryMiB;
        latestGPUNodeProvisionInstanceFamily.current = gpuNodeProvisionInstanceFamily;
        latestExtraAllocationCPUMCores.current = extraAllocationCPUMCores;
        latestExtraAllocationMemoryMiB.current = extraAllocationMemoryMiB;
        latestSystemDiskSizeGiB.current = systemDiskSizeGiB;
        latestInstanceTags.current = instanceTags;
        latestInstanceArch.current = instanceArch;
        latestZoneID.current = zoneID;
        latestCapacityTypes.current = capacityTypes;
    }, [generalNodeProvisionMinCPUCores, generalNodeProvisionMinMemoryMiB, generalNodeProvisionMaxCPUCores, generalNodeProvisionMaxMemoryMiB, 
        generalNodeProvisionInstanceFamily, gpuNodeProvisionMinGPUCards, gpuNodeProvisionMinGPUMemoryMiB, gpuNodeProvisionInstanceFamily, extraAllocationCPUMCores, 
        extraAllocationMemoryMiB, systemDiskSizeGiB, instanceTags, instanceArch, gpuNodeProvisionEnabled, zoneID, capacityTypes]);

    useEffect(() => {
        async function fetchClusterRebalanceConfiguration() {
            if (!clusterID) {
                return;
            }
            const cfg = await rebalance.getRebalanceConfiguration(clusterID);
            if (cfg.code !== 200) {
                console.error("Failed to fetch cluster costs optimization:", cfg.message);
                return;
            }

            setRebalanceCfg(cfg.data!);
            setGeneralNodeProvisionInstanceFamily(extractNodeProvisionInstanceFamily(cfg.data?.generalNodePoolSpec!, 'aws'));
            setGeneralNodeProvisionMinCPUCores(extractGeneralNodeProvisionMinCPUCores(cfg.data?.generalNodePoolSpec!, 'aws'));
            setGeneralNodeProvisionMaxCPUCores(extractGeneralNodeProvisionMaxCPUCores(cfg.data?.generalNodePoolSpec!, 'aws'));
            setGeneralNodeProvisionMinMemoryMiB(extractGeneralNodeProvisionMinMemoryMiB(cfg.data?.generalNodePoolSpec!, 'aws'));
            setGeneralNodeProvisionMaxMemoryMiB(extractGeneralNodeProvisionMaxMemoryMiB(cfg.data?.generalNodePoolSpec!, 'aws'));
            setSystemDiskSizeGiB(extractAWSGeneralNodeProvisionSystemDiskSizeGiB(cfg.data?.ec2NodeClassSpec!));
            setExtraAllocationCPUCores(extractAWSExtraResourceAllocation(cpuResourceKey, cfg.data?.generalNodePoolSpec!));
            setExtraAllocationMemoryMiB(extractAWSExtraResourceAllocation(memoryResourceKey, cfg.data?.generalNodePoolSpec!));
            setInstanceTags(extraNodeProvisionInstanceTags(cfg.data?.ec2NodeClassSpec?.tags!));
            setInstanceArch(extractGeneralNodeProvisionInstanceArch(cfg.data?.generalNodePoolSpec!));
            setZoneID(extractGeneralNodeProvisionZoneID(cfg.data?.generalNodePoolSpec!));
            setCapacityTypes(extractNodeProvisionCapacityTypes(cfg.data?.generalNodePoolSpec!));

            setGPUNodeProvisionEnabled(cfg.data?.enableGPUNodePool!);
            setGPUNodeProvisionMinGPUCards(extractGPUNodeProvisionMinGPUCards(cfg.data?.gpuNodePoolSpec!, 'aws'));
            setGPUNodeProvisionMinGPUMemoryMiB(extractGPUNodeProvisionMinGPUMemoryMiB(cfg.data?.gpuNodePoolSpec!, 'aws'));
            setGPUNodeProvisionInstanceFamily(extractNodeProvisionInstanceFamily(cfg.data?.gpuNodePoolSpec!, 'aws'));
        }
        fetchClusterRebalanceConfiguration();
    }, [clusterID])

    const updateRebalanceConfiguration = async () => {
        let updatedRebalanceCfg = rebalanceCfg;
        if (!updatedRebalanceCfg) {
            return;
        }

        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionMinValues(latestGeneralNodeProvisionMinCPUCores.current, awsCPUCoresKey, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionMaxValues(latestGeneralNodeProvisionMaxCPUCores.current, awsCPUCoresKey, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionMinValues(latestGeneralNodeProvisionMinMemoryMiB.current, awsMemoryMiBKey, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionMaxValues(latestGeneralNodeProvisionMaxMemoryMiB.current, awsMemoryMiBKey, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionInstanceFamily(latestGeneralNodeProvisionInstanceFamily.current,
            updatedRebalanceCfg.generalNodePoolSpec, 'aws');
        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionInstanceArch(latestInstanceArch.current, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionZoneID(latestZoneID.current, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.ecsGeneralNodePoolSpec = configNodeProvisionCapacityTypes(latestCapacityTypes.current, updatedRebalanceCfg.generalNodePoolSpec);

        updatedRebalanceCfg.enableGPUNodePool = latestGPUNodeProvisionEnabled.current;
        updatedRebalanceCfg.gpuNodePoolSpec = configNodeProvisionMinValues(latestGPINodeProvisionMinGPUCards.current, awsMinGPUCardsKey, updatedRebalanceCfg.gpuNodePoolSpec);
        updatedRebalanceCfg.gpuNodePoolSpec = configNodeProvisionMinValues(latestGPUNodeProvisionMinGPUMemoryMiB.current, awsMinGPUMemoryMiBKey, updatedRebalanceCfg.gpuNodePoolSpec);
        updatedRebalanceCfg.gpuNodePoolSpec = configNodeProvisionInstanceFamily(latestGPUNodeProvisionInstanceFamily.current, updatedRebalanceCfg.gpuNodePoolSpec, 'aws');
        updatedRebalanceCfg.generalNodePoolSpec = configAWSExtraAllocation(cpuResourceKey, latestExtraAllocationCPUMCores.current, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configAWSExtraAllocation(memoryResourceKey, latestExtraAllocationMemoryMiB.current, updatedRebalanceCfg.generalNodePoolSpec);

        updatedRebalanceCfg.ec2NodeClassSpec = configAWSNodeProvisionSystemDiskSize(latestSystemDiskSizeGiB.current, updatedRebalanceCfg.ec2NodeClassSpec);
        updatedRebalanceCfg.ec2NodeClassSpec = configAWSNodeProvisionInstanceTags(latestInstanceTags.current, updatedRebalanceCfg.ec2NodeClassSpec);

        setButtonLoading(true);
        const cfg = await rebalance.updateRebalanceConfiguration(clusterID!, updatedRebalanceCfg);
        if (cfg.code !== 200) {
            console.error("Failed to update cluster costs optimization:", cfg.message);
            return;
        }
        window.location.reload();
    };

    if (!rebalanceCfg) {
        return <loading.RequestLoading />
    }

    const handleChange = (value: string[]) => {
        setInstanceArch(value);
    };
    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <div className="w-full h-full min-w-[750px]">
                    <div className="w-full bg-white">
                        <div className="flex flex-col bg-white rounded-lg p-6">
                            <div className="flex flex-row justify-between w-full">
                                <div className="flex flex-row space-x-8">
                                    <div className="flex flex-col ">
                                        <div className="flex flex-row items-center">
                                            <h1 className="text-lg font-bold">Node Template Configuration for AWS</h1>
                                        </div>
                                        <p>Configure the provisioned node constraints. <span className="font-bold text-red-500">Any changes will be effective immediately.</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full bg-white p-6">
                        <div className="flex flex-row items-center border-b-[1px]">
                            <FontAwesomeIcon icon={faMicrochip} color="#1677ff" size="lg" />
                            <h1 className="text-base ml-2 font-bold">General Node Provision configuration</h1>
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Instance Family</span>
                                <span>The target instance family, like t3, m5 and so on, split by comma.</span>
                            </div>
                            <Input defaultValue={generalNodeProvisionInstanceFamily} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGeneralNodeProvisionInstanceFamily(e.target.value)} />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Instance Arch</span>
                                <span>The target instance architecture, if the instance family is configured, this field will be ignored.</span>
                            </div>
                            <div className="w-80 h-8 flex items-center absolute bottom-0 left-96">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Please select the architecture"
                                    defaultValue={instanceArch}
                                    onChange={handleChange}
                                    options={archOptions} />
                            </div>
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Capacity Type</span>
                                <span>The provisioned nodes' capacity type, on-demand or spot.</span>
                            </div>
                            <div className="w-80 h-8 flex items-center absolute bottom-0 left-96">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Please select the architecture"
                                    defaultValue={capacityTypes}
                                    onChange={handleChange}
                                    options={capacityOptions} />
                            </div>
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Instance Tags</span>
                                <span>Each provisioned node will have the configured tags, formatted as key1=value1,key2=value2.</span>
                            </div>
                            <Input defaultValue={instanceTags} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setInstanceTags(e.target.value)} />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Zone ID</span>
                                <span>Each provisioned node will located in the configured zone, formatted as us-west-1a,us-west-1b.</span>
                            </div>
                            <Input defaultValue={zoneID} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setZoneID(e.target.value)} />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">System Disk Size(GiB)</span>
                                <span>Each provisioned node's system storage size, default to be 20 GiB.</span>
                            </div>
                            <InputNumber defaultValue={systemDiskSizeGiB} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setSystemDiskSizeGiB(e!)} />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Extra CPU Allocation(mCore)</span>
                                <span>Each provisioned node will have extra CPU allocation, used only for burstable pods.</span>
                            </div>
                            <InputNumber defaultValue={extraAllocationCPUMCores} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setExtraAllocationCPUCores(e!)} />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Extra Memory Allocation(MiB)</span>
                                <span>Each provisioned node will have extra Memory allocation, used only for burstable pods.</span>
                            </div>
                            <InputNumber defaultValue={extraAllocationMemoryMiB} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setExtraAllocationMemoryMiB(e!)} />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Min CPU(Core)</span>
                                <span>Each provisioned node's CPU will be greater than(<span className="font-bold">&gt;</span>) this value. A value of 0 means unlimited.</span>
                            </div>
                            <InputNumber defaultValue={generalNodeProvisionMinCPUCores} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGeneralNodeProvisionMinCPUCores(e!)} />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Max CPU(Core)</span>
                                <span>Each provisioned node's CPU will be less than(<span className="font-bold">&lt;</span>) this value. A value of 0 means unlimited.</span>
                            </div>
                            <InputNumber defaultValue={generalNodeProvisionMaxCPUCores} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGeneralNodeProvisionMaxCPUCores(e!)} />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Min Memory(MiB)</span>
                                <span>Each provisioned nodes' Memory will be greater than(<span className="font-bold">&gt;</span>) than this value. A value of 0 means unlimited.</span>
                            </div>
                            <InputNumber defaultValue={generalNodeProvisionMinMemoryMiB} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGeneralNodeProvisionMinMemoryMiB(e!)} />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Max Memory(MiB)</span>
                                <span>Each provisioned node's Memory will be less than(<span className="font-bold">&lt;</span>) than this value. A value of 0 means unlimited.</span>
                            </div>
                            <InputNumber defaultValue={generalNodeProvisionMaxMemoryMiB} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGeneralNodeProvisionMaxMemoryMiB(e!)} />
                        </div>
                        {/* <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Over Provisioning CPU Percentage</span>
                                <span>CloudPilot AI will provide a more specific percentage of CPU for workload requests.</span>
                            </div>
                            <InputNumber defaultValue={0}  addonAfter="%" className="w-80 h-8 flex items-center absolute bottom-0 left-96" />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Over Provisioning Memory Percentage</span>
                                <span>CloudPilot AI will provide a more specific percentage of Memory for workload requests.</span>
                            </div>
                            <InputNumber defaultValue={0}  addonAfter="%" className="w-80 h-8 flex items-center absolute bottom-0 left-96" />
                        </div> */}
                    </div>
                    <div className="w-full bg-white p-6">
                        <div className="flex flex-row items-center border-b-[1px]">
                            <FontAwesomeIcon icon={faBrain} color="#1677ff" size="lg" />
                            <h1 className="text-base ml-2 font-bold">GPU Node Provision configuration</h1>
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Enable GPU Node Provision</span>
                                <span className="mb-1">
                                    You can disable the GPU Node Provision to save on expenses if you are not running any GPU workloads.
                                </span>
                                <small>
                                    When disabled, these Nodes will be marked with a specific taint (<code>cloudpilot.ai/provider-disable</code>) to prevent GPU workloads from being provisioned onto it
                                </small>
                            </div>
                            <Switch
                                className="bottom-0 left-6"
                                defaultChecked={gpuNodeProvisionEnabled}
                                onChange={setGPUNodeProvisionEnabled}
                            />
                            {/* <Input defaultValue={gpuNodeProvisionInstanceFamily} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGPUNodeProvisionInstanceFamily(e.target.value)} /> */}
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Instance Family</span>
                                <span>The target instance family, like g3, g4 and so on, split by comma.</span>
                            </div>
                            <Input defaultValue={gpuNodeProvisionInstanceFamily} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGPUNodeProvisionInstanceFamily(e.target.value)} />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Min Graphic Cards(Card)</span>
                                <span>Each provisioned nodes' GPU cards will be greater(<span className="font-bold text-red-500">&gt;</span>) than this value.</span>
                            </div>
                            <InputNumber defaultValue={gpuNodeProvisionMinGPUCards} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGPUNodeProvisionMinGPUCards(e!)} />
                        </div>
                        <div className="w-full flex flex-row relative mt-6">
                            <div className="w-80 flex flex-col mr-10">
                                <span className="font-bold">Min Graphic Memory(MiB)</span>
                                <span>Each provisioned nodes' Graphic Memroy will be greater(<span className="font-bold text-red-500">&gt;</span>) than this value.</span>
                            </div>
                            <InputNumber defaultValue={gpuNodeProvisionMinGPUMemoryMiB} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGPUNodeProvisionMinGPUMemoryMiB(e!)} />
                        </div>
                    </div>
                    <div className="w-full bg-white p-6 rounded-b-lg">
                        <div className="w-full h-14 border-t-[1px] relative">
                            <Button
                                className="h-8 w-32 absolute right-0 bottom-0"
                                type="primary"
                                onClick={updateRebalanceConfiguration}
                                loading={buttonLoading}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </Space>
        </>
    )
}